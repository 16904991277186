import HttpFactory from "../factory";
import type {ColumnFilter} from "@tanstack/vue-table";

class ProductModule extends HttpFactory {

  async deviceByMasterList(page: number, pageSize: number, sort: string, filters: ColumnFilter[], masterDeviceID: string) {
    return await this.call(`/api/product/devices-by-master-list/${masterDeviceID}`, "post",
      {
        query: {
          page,
          page_size: pageSize,
          sort,
        },
      },
      { filters: filters },
    );
  }

  async masterDeviceList(searchString: string) {
    return await this.call("/api/product/master-devices-list", "get", {query: {search: searchString}});
  }

  async deviceTypesForBuyout() {
    return await this.call("/api/product/device-types-for-buyout", "get");
  }

  async manufacturersByCategory(categoryIdentifier: string) {
    return await this.call("/api/product/manufacturers-by-category/{category_identifier}", "get", {
      path: { category_identifier: categoryIdentifier }
    });
  }

  async listManufacturers() {
    return await this.call("/api/product/manufacturers/list", "get");
  }

  async metaMasterByCategoryByManufacturer(categoryIdentifier: string, manufacturerSeoName: string, partnerSeoName: string = "") {
    return await this.call("/api/product/meta-masters-by-category-by-manufacturer/{category_identifier}/{manufacturer_seo_name}/{partner_seo_name}", "get", {
      path: { category_identifier: categoryIdentifier, manufacturer_seo_name: manufacturerSeoName, partner_seo_name: partnerSeoName}
    });
  }

  async metaMasterWithHighestBuyoutPrice() {
    return await this.call("/api/product/meta-masters-with-highest-buyout-price", "get");
  }

  async attrChoicesByMetaMaster(metaMasterId: string) {
    return await this.call("/api/product/attr-choices-by-meta-master/{meta_master_id}", "get", {
      path: { meta_master_id: metaMasterId }
    });
  }

  async attrChoicesForNewByMetaMaster(metaMasterId: string, partnerId: string, buyoutDeviceId: string, isOther: number) {
    return await this.call("/api/product/attr-choices-for-new-by-meta-master/{meta_master_id}/{partner_id}/{buyout_device_id}/{is_other}", "get", {
      path: { meta_master_id: metaMasterId, partner_id: partnerId, buyout_device_id: buyoutDeviceId, is_other: isOther }
    });
  }

  async colorChoicesByMasterDevice(masterProductId: string) {
    return await this.call("/api/product/color-choices-by-master-device/{master_product_id}", "get", {
      path: { master_product_id: masterProductId }
    });
  }

  async colorChoicesByMasterDeviceByPartnerNew(masterProductId: string, partnerId: string) {
    return await this.call("/api/product/color-choices-by-master-device-new/{master_product_id}/{partner_id}", "get", {
      path: { master_product_id: masterProductId, partner_id: partnerId }
    });
  }

  async qualityChoicesByColorAndMaster(masterProductId: string, productColorId: string) {
    return await this.call("/api/product/quality-choices-by-color-and-master/{master_product_id}/{product_color_id}", "get", {
      path: { master_product_id: masterProductId, product_color_id: productColorId }
    });
  }

  async qualityChoicesBuyoutFalseForBuyout(masterProductId: string, productColorId: string, buyoutId: string) {
    return await this.call("/api/product/quality-choices-buyout-false-for-buyout/{master_product_id}/{product_color_id}/{buyout_id}", "get", {
      path: { master_product_id: masterProductId, product_color_id: productColorId, buyout_id: buyoutId }
    });
  }

  async getDeviceDescription(seoName: string) {
    return await this.call("/api/product/retrieve-description/{seo_name}", "get", {path: {seo_name: seoName}});
  }

  async getDeviceTechnicalParameters(seoName: string) {
    return await this.call("/api/product/retrieve-technical-parameters/{seo_name}", "get", {path: {seo_name: seoName}});
  }

  async getDeviceAlternatives(seoName: string) {
    return await this.call("/api/product/device-alternatives/{seo_name}", "get", {path: {seo_name: seoName}});
  }

  async devicePreview(identifier: string) {
    return await this.call("/api/product/device-preview/{identifier}", "get", {path: {identifier}});
  }

  async getMasterDeviceDetail(seoName: string) {
    return await this.call("/api/product/master-device-detail/{seo_name}", "get", {path: {seo_name: seoName}});
  }

  async availableMpStoresForProduct(code: string) {
    return await this.call("/api/product/get-available-mp-stores-for-product/{code}", "get", { path: {code}});
  }
}

export default ProductModule;
