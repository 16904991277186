import HttpFactory from "../factory";

class FaqModule extends HttpFactory {
  async getFaq(query: { identifier?: string; partner?: string }) {
    return await this.call("/api/faq/faq", "get", {
      query });
  }
}

export default FaqModule;
