import { h, defineAsyncComponent } from "vue";
import type { IconSet, IconProps } from "vuetify";

export const iconSetSamsung: IconSet = {
  component: (props: IconProps) => h(
    props.tag,
    {},
    [h(defineAsyncComponent(() => import(`./assets/svg/samsung/${props.icon}.svg`).catch(() => {
      // eslint-disable-next-line no-console
      console.warn(`Failed to load icon ${props.icon}`);
      return { render: () => "" };
    })), { class: `icon icon--${props.icon}` })]
  )
};
