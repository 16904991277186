import HttpFactory from "../factory";

class UserModule extends HttpFactory {

  async list() {
    return await this.call("/api/user/list", "get");
  }

  async userData() {
    return await this.call("/api/user/user-data", "get");
  }

  async userPrinter() {
    return await this.call("/api/user/user-printer", "get");
  }

  async userAdditionalData() {
    return await this.call("/api/user/user-additional-data", "get");
  }

  async countryChoicesData() {
    return await this.call("/api/user/country-choices-data", "get");
  }

  async updateUserData(data: object) {
    return await this.call("/api/user/update-user-data", "post", {}, data);
  }

  async updateUserAdditionalData(data: object) {
    return await this.call("/api/user/update-user-additional-data", "post", {}, data);
  }

  async changePassword(data: object) {
    return await this.call("/api/user/change-password", "post", {}, data);
  }

  async userThreeLatestBuyouts() {
    return await this.call("/api/user/user-three-latest-buyouts", "get");
  }

  async getUserBuyouts() {
    return await this.call("/api/user/get-user-buyouts", "get");
  }

  async educationVideos() {
    return await this.call("/api/user/education-videos", "get");
  }

  async getEmail() {
    return await this.call("/api/user/get-email", "get");
  }

  async partnerDetail(seoName: string) {
    return await this.call("/api/user/partner-detail/{seo_name}", "get", {path: {seo_name: seoName}});
  }

  async partnerByAmbassadorRef(referenceCode: string) {
    return await this.call("/api/user/partner-detail-ref/{reference_code}", "get", { path: { reference_code: referenceCode } });
  }

  async manufacturerPartnerByAmbassadorRef(referenceCode: string) {
    return await this.call("/api/user/manufacturer-partner-detail-ref/{reference_code}", "get", { path: { reference_code: referenceCode } });
  }

  async inviteInfo() {
    return await this.call("/api/user/invite-info", "get");
  }

  async getUserProgress() {
    return await this.call("/api/user/get-user-progress", "get");
  }

  async loadRewards() {
    return await this.call("/api/user/load-rewards", "get");
  }

  async getNews() {
    return await this.call("/api/user/get-news", "get");
  }

  async infoReward() {
    return await this.call("/api/user/info-reward", "get");
  }

  async changePasswordTradein(data: object) {
    return await this.call("/api/user/change-password-tradein", "post", {}, data);
  }

  async chatRoom() {
    return await this.call("/api/user/chat-room", "get");
  }

  async listPartnersAmbassadors(pageNumber: number, pageSize: number, sort: string) {
    return await this.call("/api/user/partner/ambassadors", "get", {
      query: { page: pageNumber, page_size: pageSize, sort }
    });
  }

  async getPartnersAmbassador(employeeId: string) {
    return await this.call("/api/user/partner/ambassadors/{employee_id}", "get", { params: { employee_id: employeeId } });
  }

  async ssoAuthUrl(partnerSeoName: string) {
    return await this.call("/api/user/{partner_seo_name}/sso-auth-url", "get", { params: { partner_seo_name: partnerSeoName } });
  }

  async ssoLogin(partnerSeoName: string) {
    return await this.call("/api/user/{partner_seo_name}/sso-login", "get", { params: { partner_seo_name: partnerSeoName } });
  }

  async singleCountryData(identifier: string) {
    return await this.call("/api/user/single-country-data/{identifier}", "get", {
      path: {identifier}
    });
  }
}

export default UserModule;
