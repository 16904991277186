import HttpFactory from "../factory";

class DeliveryModule extends HttpFactory {
  async availableDeliveryTypes(userBuyoutCode: string) {
    return await this.call("/api/delivery/available-delivery-types/{user_buyout_code}", "get", {path: {user_buyout_code: userBuyoutCode}});
  }

  async getAvailableDeliveryMethods(deviceCode: string) {
    return await this.call("/api/delivery/get-available-deliveries/{device_code}", "get", {path: {device_code: deviceCode}});
  }

  async deliveryTypeSlots(identifier: string) {
    return await this.call("/api/delivery/delivery-type-slots/{identifier}", "get", {path: {identifier}});
  }

  async orderDelivery() {
    return await this.call("/api/delivery/order-delivery", "post");
  }

  async orderDeliveryLater() {
    return await this.call("/api/delivery/order-delivery-later", "post");
  }

  async staffOrderDelivery() {
    return await this.call("/api/delivery/staff-order-delivery", "post");
  }
  async handleBuyoutOnBrandStore(userBuyoutCode: string | null) {
    return await this.call("/api/delivery/handle-buyout-on-brand-store", "post", {
    },
    {
      buyout_registration_number: userBuyoutCode
    });
  }
  async handleBuyoutOnServiceStore(userBuyoutCode: string | null) {
    return await this.call("/api/delivery/handle-buyout-on-service-store", "post", {
    },
    {
      buyout_registration_number: userBuyoutCode
    });
  }
}


export default DeliveryModule;
